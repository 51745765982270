import React from "react";
import HeroImg from "../../assets/images/hero-img.png";
import AboutImg from "../../assets/images/about-img.png";
import Media from "../socials/Media";
import "./img.css";

const Img = ({ Icon, Text, AboutPic, AboutText, AboutProfile, HeroProfile }) => {
  return (
    <div className="img-container">
      {Icon && <Media PhoneMedia="phone-media" AboutMedia='about-media' />}
      {Text && (
        <div className={"img-text-container " + AboutText}>
          <p className="img-text eat">eat</p>
          <p className="img-text">breath</p>
          <p className="img-text">code</p>
        </div>
      )}
      {HeroProfile && <img className={"profile-pic "} src={HeroImg} alt="hero-img" />}
      {AboutProfile && (
        <img
          className={"profile-pic " + AboutPic}
          src={AboutImg}
          alt="about-img"
        />
      )}
    </div>
  );
};

export default Img;
