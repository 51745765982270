import React, { useRef, useState } from "react";
import tunderIcon from "../../assets/svg/tunder-icon.svg";
import naruto from "../../assets/images/naruto.png";
import demonSlayer from "../../assets/images/demonSlayer.png";
import castlevania from "../../assets/images/castlevania.png";
import music1 from "../../assets/images/music1.png";
import music2 from "../../assets/images/music2.png";
import music3 from "../../assets/images/music3.png";
import youtube1 from "../../assets/images/youtube1.png";
import youtube2 from "../../assets/images/youtube2.png";
import youtube3 from "../../assets/images/youtube4.png";
import Button from "../../ui/button/Button";
import "./interest.css";
import useIO from "../../hooks/useIO";
import AnimateText from "../animate/AnimateText";

const Interest = () => {
  const interestRef = useRef(null);

  const { visibleElement } = useIO({ ref: interestRef });

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <section className="personal-interest_wrapper" ref={interestRef}>
      <div className="">
        {visibleElement ? (
          <div className="container interest-container">
            <img src={tunderIcon} alt="pin-img" className="pin-img" />
            <h2 className="header experience-header">
              <AnimateText value="Personal Interest" gradient />
            </h2>
          </div>
        ) : null}
        <div className="hobbies">
          <div className="hobbies-div">
            <h1
              className={`hobbie ${
                toggleState === 1 ? "active-tab" : "hobbie"
              }`}
              onClick={() => toggleTab(1)}
            >
              Music
            </h1>

            <div className="mobile-content-tab">
              <div
                className={`hobbie-flex ${
                  toggleState === 1 ? "active-content" : "content"
                }`}
              >
                <div className="hobbie-flex_left">
                  <p className="hobbie-flex_left_text">
                    <AnimateText
                      word="I'm always listening to music while working and I love to find bands that deserve more recognition.
                      Check out my favourite tracks in a playlist made for you."
                    />
                  </p>

                  <Button
                    text="Explore Playlist"
                    href="https://open.spotify.com/playlist/5hte3hEWmac3JqM05ldEX9?si=6ea98e53f6884f4e&nd=1"
                  />
                </div>

                <div className="hobbie-flex_right">
                  <div className="hobbie-flex_right_card">
                    <img
                      src={music1}
                      alt=""
                      className="hobbie-flex_right_card_img"
                    />
                  </div>
                  <div className="hobbie-flex_right_card">
                    <img
                      src={music2}
                      alt=""
                      className="hobbie-flex_right_card_img"
                    />
                  </div>
                  <div className="hobbie-flex_right_card">
                    <img
                      src={music3}
                      alt=""
                      className="hobbie-flex_right_card_img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hobbies-div">
            <h1
              className={`hobbie ${
                toggleState === 2 ? "active-tab" : "hobbie"
              }`}
              onClick={() => toggleTab(2)}
            >
              Movies
            </h1>

            <div className="mobile-content-tab">
              <div
                className={`hobbie-flex ${
                  toggleState === 2 ? "active-content" : "content"
                }`}
              >
                <div className="hobbie-flex_left">
                  <p className="hobbie-flex_left_text">
                    <AnimateText word="You'll often find me deeply engrossed in the vibrant universe of anime. From the adventures of Naruto to the thrilling tales of Demon Slayer and Castlevania." />
                  </p>

                  <Button
                    text="Explore Playlist"
                    href="https://www.netflix.com/title/81091393"
                  />
                </div>

                <div className="hobbie-flex_right">
                  <div className="hobbie-flex_right_card">
                    <img
                      src={naruto}
                      alt=""
                      className="hobbie-flex_right_card_img"
                    />
                  </div>
                  <div className="hobbie-flex_right_card">
                    <img
                      src={demonSlayer}
                      alt=""
                      className="hobbie-flex_right_card_img"
                    />
                  </div>
                  <div className="hobbie-flex_right_card">
                    <img
                      src={castlevania}
                      alt=""
                      className="hobbie-flex_right_card_img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hobbies-div">
            <h1
              className={`hobbie ${
                toggleState === 3 ? "active-tab" : "hobbie"
              }`}
              onClick={() => toggleTab(3)}
            >
              YouTube
            </h1>

            <div className="mobile-content-tab">
              <div
                className={`hobbie-flex ${
                  toggleState === 3 ? "active-content" : "content"
                }`}
              >
                <div className="hobbie-flex_left">
                  <p className="hobbie-flex_left_text">
                    <AnimateText word=" Check out 'My Little Sprout,' my YouTube channel where I share heartwarming moments with my baby. Join us on our adorable journey together! 📷👶💖 Don't forget to subscribe, like and share!" />
                  </p>

                  <Button
                    text="Explore Playlist"
                    href="https://www.youtube.com/@mercyokobi7768"
                  />
                </div>

                <div className="hobbie-flex_right">
                  <div className="hobbie-flex_right_card">
                    <img
                      src={youtube1}
                      alt=""
                      className="hobbie-flex_right_card_img"
                    />
                  </div>
                  <div className="hobbie-flex_right_card">
                    <img
                      src={youtube2}
                      alt=""
                      className="hobbie-flex_right_card_img"
                    />
                  </div>
                  <div className="hobbie-flex_right_card">
                    <img
                      src={youtube3}
                      alt=""
                      className="hobbie-flex_right_card_img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {visibleElement ? (
          <div className="content-tab">
            <div
              className={`hobbie-flex ${
                toggleState === 1 ? "active-content" : "content"
              }`}
            >
              <div className="hobbie-flex_left">
                <p className="hobbie-flex_left_text">
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.2}s` }}
                  >
                    I'm always listening to music while working and I{" "}
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.4}s` }}
                  >
                    love to find bands that deserve more recognition.
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.6}s` }}
                  >
                    Check out my favourite tracks in a playlist made
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.8}s` }}
                  >
                    for you
                  </span>
                </p>

                <Button
                  text="Explore Playlist"
                  href="https://open.spotify.com/playlist/5hte3hEWmac3JqM05ldEX9?si=6ea98e53f6884f4e&nd=1"
                />
              </div>

              <div className="hobbie-flex_right">
                <div className="hobbie-flex_right_card">
                  <img
                    src={music1}
                    alt=""
                    className="hobbie-flex_right_card_img"
                  />
                </div>
                <div className="hobbie-flex_right_card">
                  <img
                    src={music2}
                    alt=""
                    className="hobbie-flex_right_card_img"
                  />
                </div>
                <div className="hobbie-flex_right_card">
                  <img
                    src={music3}
                    alt=""
                    className="hobbie-flex_right_card_img"
                  />
                </div>
              </div>
            </div>

            <div
              className={`hobbie-flex ${
                toggleState === 2 ? "active-content" : "content"
              }`}
            >
              <div className="hobbie-flex_left">
                <p className="hobbie-flex_left_text">
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.2}s` }}
                  >
                    You'll often find me deeply engrossed in the
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.4}s` }}
                  >
                    vibrant universe of anime. From the adventures
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.6}s` }}
                  >
                    of Naruto to the thrilling tales of Demon Slayer
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.8}s` }}
                  >
                    and Castlevania.
                  </span>
                </p>

                <Button
                  text="Explore Playlist"
                  href="https://www.netflix.com/title/81091393"
                />
              </div>

              <div className="hobbie-flex_right">
                <div className="hobbie-flex_right_card">
                  <img
                    src={naruto}
                    alt=""
                    className="hobbie-flex_right_card_img"
                  />
                </div>
                <div className="hobbie-flex_right_card">
                  <img
                    src={demonSlayer}
                    alt=""
                    className="hobbie-flex_right_card_img"
                  />
                </div>
                <div className="hobbie-flex_right_card">
                  <img
                    src={castlevania}
                    alt=""
                    className="hobbie-flex_right_card_img"
                  />
                </div>
              </div>
            </div>

            <div
              className={`hobbie-flex ${
                toggleState === 3 ? "active-content" : "content"
              }`}
            >
              <div className="hobbie-flex_left">
                <p className="hobbie-flex_left_text">
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.2}s` }}
                  >
                    Check out "My Little Sprout," my YouTube
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.4}s` }}
                  >
                    channel where I share heartwarming moments
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.6}s` }}
                  >
                    with my baby. Join us on our adorable journey
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${0.8}s` }}
                  >
                    together! 📷👶💖 Don't forget to subscribe, like
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${1}s` }}
                  >
                    and share!
                  </span>
                </p>

                <Button
                  text="Explore Playlist"
                  href="https://www.youtube.com/@mercyokobi7768"
                />
              </div>

              <div className="hobbie-flex_right">
                <div className="hobbie-flex_right_card">
                  <img
                    src={youtube1}
                    alt=""
                    className="hobbie-flex_right_card_img"
                  />
                </div>
                <div className="hobbie-flex_right_card">
                  <img
                    src={youtube2}
                    alt=""
                    className="hobbie-flex_right_card_img"
                  />
                </div>
                <div className="hobbie-flex_right_card">
                  <img
                    src={youtube3}
                    alt=""
                    className="hobbie-flex_right_card_img"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Interest;
