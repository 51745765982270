import React, { useRef } from "react";
import Img from "../imageContainer/Img";
import Blur from "../../assets/svg/blur.svg";
import Skeleton from "../../assets/svg/skeleton.svg";
import ArrowDown from "../../assets/svg/arrow-down.svg";
import "./hero.css";
import useIO from "../../hooks/useIO";
import AnimateText from "../animate/AnimateText";

const Hero = () => {
  const heroRef = useRef(null);

  const { visibleElement } = useIO({ ref: heroRef });

  return (
    <div className="hero-container" ref={heroRef}>
      <div className="hero-wrapper">
        <div className="hero-wrapper_items">
          <p className="hero-wrapper_items_text">I MAKE IT HAPPEN</p>
          {visibleElement ? (
            <div className="hero-head-wrapper">
              <h1 className="hero-head">
                <span className="hero-head_items">
                  <AnimateText value="Websites" />
                </span>{" "}
                <span className="hero-head_items">
                  <AnimateText value="Webapps" />
                </span>
              </h1>
              <h1 className="hero-head">
                <span>
                  <AnimateText value="Websites" />
                </span>{" "}
                <span>
                  <AnimateText value="Webapps" />
                </span>
              </h1>
            </div>
          ) : null}

          <div className="hero-sub-content_container">
            <Img HeroProfile />
            <div className="arrow-down">
              <img src={ArrowDown} alt="arrow" />
            </div>

            <div className="hero-wrapper_items">
              <div className="frontend-dev-wrapper">
                <h1 className="f-d">
                  <span className="f-d_items">
                    <AnimateText
                      value="Frontend
                  Developer"
                      gradient
                    />
                  </span>
                </h1>
                <p className="hero-tiny-text">
                  Specializing in creating sleek and premium
                  <span className="ui"> User Interfaces</span>
                </p>
              </div>
              <div className="blur-skeleton-container">
                <div className="blur-container">
                  <img className="blur" src={Blur} alt="blur" />
                </div>
                <div className="skeleton-container">
                  <img className="skeleton" src={Skeleton} alt="skeleton" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
