import React from "react";
import Github from "../../assets/svg/github.svg";
import Twitter from "../../assets/svg/twitter.svg";
import "./media.css";
import Instagram from "../../assets/svg/instagram.svg";
import Linkedin from "../../assets/svg/linkedin.svg";

const Media = ({ props, PhoneMedia }) => {
  return (
    <div className={PhoneMedia}>
      <div className={"socials-container " + props}>
        <div className="icon-wrapper github">
          <div className="tooltip">Github</div>
          <a
            href="https://github.com/Osetoham"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-media" src={Github} alt="" />
          </a>
        </div>
        <div className="icon-wrapper twitter">
          <div className="tooltip">Twitter</div>
          <a
            href="https://x.com/mercy__o/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-media" src={Twitter} alt="" />
          </a>
        </div>
        <div className="icon-wrapper instagram">
          <div className="tooltip">Instagram</div>
          <a
            href="https://www.instagram.com/mercy_okobi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-media" src={Instagram} alt="" />
          </a>
        </div>
        <div className="icon-wrapper linedin">
          <div className="tooltip">Linkedin</div>
          <a
            href="https://www.linkedin.com/in/oseokobi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-media" src={Linkedin} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Media;
