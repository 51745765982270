import React from "react";
import Nav from "../../components/nav/Nav";
import Img from "../../components/imageContainer/Img";
import Blur from "../../assets/svg/blur.svg";
import Experience from "../../components/experience/Experience";
import Interest from "../../components/personalInterest/Interest";
import Footer from "../../components/footer/Footer";
import "../about/about.css";


const About = () => {
  return (
    <div>
      <section className="about-section">
        <div className="container about-wrapper">
          <Nav />
          <div className="about-wrapper-flex">
            <div className="about-container">
              <div className="about-container_items">
                <h2 className="header about-header fadeRight">
                  Entered the world in Nigeria, Now I sing in my shower in{" "}
                  <span className="header-cross">Lagos,</span>{" "}
                  <span className="header-cross">Dubai,</span> Manchester
                </h2>
              </div>

              <div className=" about-container_items hero-wrapper_items">
                <div className="blur-skeleton-container">
                  <div className="about-blur-container">
                    <img className="blur" src={Blur} alt="blur" />
                  </div>
                </div>
                <div className="item-right">
                  A journey of curiosity and{" "}
                  <span className="about-line"></span> explorations
                </div>
              </div>
            </div>
            <div className="about-hero-flex">
              <Img Icon Text AboutPic="about-pic" AboutText="about-text" AboutProfile/>

              <div className="about-hero-text">
                <span
                  className="ani-span SlidUp"
                  style={{ animationDelay: `${0.2}s` }}
                >
                  I'm a Front-end developer with 2+ years of{" "}
                </span>
                <span
                  className="ani-span SlidUp"
                  style={{ animationDelay: `${0.4}s` }}
                >
                  experience in building products and appealing web
                </span>
                <span
                  className="ani-span SlidUp"
                  style={{ animationDelay: `${0.6}s` }}
                >
                  experiences. I've collaborated with individuals and
                </span>
                <span
                  className="ani-span SlidUp"
                  style={{ animationDelay: `${0.8}s` }}
                >
                  teams to build experiences for startups and large
                </span>
                <span
                  className="ani-span SlidUp"
                  style={{ animationDelay: `${1}s` }}
                >
                  enterprises.
                </span>

                <div className="about-hero-subtext">
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${1.2}s` }}
                  >
                    Each project is an opportunity to constantly seek
                  </span>
                  <span
                    className="ani-span SlidUp"
                    style={{ animationDelay: `${1.4}s` }}
                  >
                    out innovative solutions to everyday problems.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Experience />
      <Interest />
      <div className="about-footer">
        <Footer />
      </div>
    </div>
  );
};

export default About;
