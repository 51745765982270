import { useEffect, useState } from "react";

function useIO({ ref }) {
  const [visibleElement, setVisibleElement] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setVisibleElement(entry.isIntersecting);
          observer.unobserve(ref.current);
        }
      },
    //   { threshold: 0.2 }
    );
    observer.observe(ref.current);
  }, [ref]);

  return {
    visibleElement,
  };
}

export default useIO;
