import React, { Fragment } from "react";

const AnimateText = ({ value, word, gradient, delay = 0 }) => {
  return (
    <>
      <span className="span-div">
        {word?.split(" ").map((eachWord, index) => (
          <Fragment key={index}>
            <span
              className={`${
                gradient ? "gradient SlidUp " : "WordSlidUp each-word "
              } `}
              style={{
                animationDelay: `${
                  (index + 1) * (gradient ? 0.2 : 0.05) + delay
                }s`,
              }}
            >
              {eachWord === " " ? " " : eachWord}
            </span>
            {eachWord === " " ? " " : " "}
          </Fragment>
        ))}
      </span>
      <span className="span-div">
        {value?.split("").map((eachLetter, index) => (
          <Fragment key={index}>
            <span
              className="SlidUp"
              style={{ animationDelay: `${index * 0.05 + delay}s` }}
            >
              {eachLetter === " " ? " " : eachLetter}
            </span>
            {eachLetter === " " ? " " : ""}
          </Fragment>
        ))}
      </span>
    </>
  );
};

export default AnimateText;
