import Header from "../header/Header";
import Project from "../project/Project";
import Stack from "../stack/Stack";
import Footer from "../footer/Footer";

const Home = () => {
  return (
    <div className="website-container">
      <div className="inner-website-container">
        <Header />
        <Project />
        <Stack />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
