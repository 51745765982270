import React, { useRef } from "react";
import Pin from "../../assets/svg/pin.svg";
import ArrowLeft from "../../assets/svg/arrow-left.svg";
import Message from "../../assets/svg/msg.svg";
import "./experience.css";
import AnimateText from "../animate/AnimateText";
import useIO from "../../hooks/useIO";

const Experience = () => {
  const expRef = useRef(null);
  const { visibleElement } = useIO({ ref: expRef });

  return (
    <section>
      <div className="container experience-container" ref={expRef}>
        {visibleElement && (
          <div>
            <img className="pin-img" src={Pin} alt="pin-img" />
            <div className="exp-msg_container">
              <h2 className="header experience-header exp-msg_header">
                <AnimateText value="Experience" gradient />
              </h2>
              <img className="msg" src={Message} alt="msg" />
            </div>
          </div>
        )}

        <SingleExperience
          company="Send"
          date="Mar 2022 -  Sept 2022"
          description="Planned, built, tested and maintained scalable front end web applications for their freight platform."
        />

        <SingleExperience
          company="Bitiska"
          date="Mar 2021 - June 2022"
          description="Collaborated with designers to plan and execute designs and interaction
        on websites and webapps."
        />
        <SingleExperience
          company="Freelance Dev"
          date="Sept 2022 - Present"
          description="Successfully collaborated with diverse clients, delivering tailored solutions to meet their unique needs."
        />

        <SingleExperienceLink
          company="Future"
          href="mailto:mercyokobi@gmail.com"
          alt="You Decide"
          description="If you want to chat about a project — Take a minute, I’d love to hear about your project, just drop me a message"
        />
      </div>
    </section>
  );
};

function SingleExperience({ company, date, description }) {
  const expRef = useRef(null);
  const { visibleElement } = useIO({ ref: expRef });

  return (
    <div
      className={`experience-table ${visibleElement ? "SlidUp" : ""}`}
      style={{ animationDelay: "0.4s" }}
      ref={expRef}
    >
      <div className="company-name">{company}</div>
      <div className="experience-date">{date}</div>
      <p className="table-text">{description}</p>
    </div>
  );
}

function SingleExperienceLink({ company, description, href, alt }) {
  const expRef2 = useRef(null);
  const { visibleElement } = useIO({ ref: expRef2 });

  return (
    <div
      className={`experience-table ${visibleElement ? "SlidUp" : ""}`}
      style={{ animationDelay: "0.4s" }}
      ref={expRef2}
    >
      <div className="company-name">{company}</div>
      <a href={href} className="future-experience">
        {alt}
      </a>
      <img src={ArrowLeft} alt="arrow-left" className="arrow-left" />
      <p className="table-text">{description}.</p>
    </div>
  );
}

export default Experience;
