import React from "react";
import Nav from "../nav/Nav";
import Hero from "../hero/Hero";
import "./header.css";

const Header = () => {
  return (
    <section className="section">
      <div className="container header-container">
        <Nav />
        <div className="header-hero-wrapper">
          <Hero />
        </div>
      </div>
    </section>
  );
};

export default Header;
