import React, { useRef, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import logo from "../src/assets/svg/logo.svg";
import Home from "./components/home/Home";
import About from "./pages/about/About";
import "../src/components/animate/animate.css";
import { gsap } from "gsap";

const App = () => {
  const loaderRef = useRef(null);
  const counterRef = useRef(null);
  const [pageCanPlay, setPageCanPlay] = useState(false);

  useEffect(() => {
    function startLoader() {
      let currentValue = 0;

      function updateCounter() {
        if (currentValue === 100) {
          gsap.to(".counter", 0.25, {
            opacity: 0,
          });

          gsap.to(".logo-name-wrapper", {
            opacity: 0,
            ease: "power4.inOut",
          });

          gsap.to(".bar", 1, {
            height: 0,
            stagger: {
              amount: 0.5,
            },
            ease: "power4.inOut",
          });

          gsap.fromTo(
            loaderRef.current,
            { pointerEvents: "none" },
            {
              delay: 0.08,
              duration: 0.5,
              onComplete() {
                setPageCanPlay(true);
              },
            }
          );
          return;
        }
        currentValue += Math.floor(Math.random() * 10) + 1;

        if (currentValue > 100) {
          currentValue = 100;
        }

        counterRef.current.textContent = currentValue;

        let delay = Math.floor(Math.random() * 250) + 50;
        setTimeout(updateCounter, delay);
      }
      updateCounter();
    }

    startLoader();
  }, [loaderRef]);

  return (
    <div className={pageCanPlay ? "page-can-play" : ""}>
      <div ref={loaderRef}>
        <h1 className="counter" ref={counterRef}>
          0
        </h1>

        <div className="overlay">
          <div className="logo-name-wrapper">
            <div className="logo-name">
              <img src={logo} alt="" />
              <div className="inner-logo-name">
                <h2>
                  MERCY <br /> CHINEDUM...
                </h2>
                <div className="line-container">
                  <div className="loader-line"></div>
                  <div className="loader-line-dots">...</div>
                </div>
              </div>

              <h2 className="loader-copyright">© 2024</h2>
            </div>
          </div>

          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>

      <Router>
        <AppRoutes />
      </Router>
    </div>
  );
};

function AppRoutes() {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
    </Routes>
  );
}

export default App;
