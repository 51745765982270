import React, { useRef } from "react";
import tunderIcon from "../../assets/svg/tunder-icon.svg";
import MyLogo from "../../assets/images/my-logo.png";
import MyMobileLogo from "../../assets/images/my-logo-mobile.png";
import Logo from "../logo/logo";
import Html from "../../assets/svg/html.svg";
import Css from "../../assets/svg/css.svg";
import Js from "../../assets/svg/js.svg";
import Sass from "../../assets/svg/sass.svg";
import ReactJs from "../../assets/svg/react.svg";
import Gatsby from "../../assets/svg/gatsby.svg";
import TailWind from "../../assets/svg/tailwind.svg";
import StyledComponent from "../../assets/images/styled-component.png";
import Redux from "../../assets/svg/redux.svg";
import "./stack.css";
import AnimateText from "../animate/AnimateText";
import useIO from "../../hooks/useIO";

const Stack = () => {
  const stackRef = useRef(null);

  const { visibleElement } = useIO({ ref: stackRef });

  return (
    <section ref={stackRef} className="stack-cover">
      <div className="container stack-container">
        {visibleElement ? (
          <div>
            <img className="pin-img" src={tunderIcon} alt="pin" />
            <h2 className="header stack-header">
              <AnimateText word="I Build Modern Experience With These Stacks" />
            </h2>
          </div>
        ) : null}

        <div className="stack-logo-container">
          <img src={MyLogo} alt="logo" className="my-logo" />
          <img
            src={MyMobileLogo}
            alt="logo"
            className="my-mobile-logo"
          />
          <div className="stark-flex">
            <div className="start-flex_item">
              <Logo logo={Html} text={"HTML 5"} />
              <div className="logo-second_item">
                <Logo logo={Css} text={"CSS 3"} />
              </div>
            </div>
            <div className="start-flex_item">
              <Logo logo={Js} text={"Javascript"} />
            </div>
            <div className="start-flex_item">
              <Logo logo={Sass} text={"SCSS"} logoTop="logo-top" />
              <div className="logo-second_item">
                <Logo logo={ReactJs} text={"React JS"} />
              </div>
            </div>
            <div className="start-flex_item">
              <Logo logo={Gatsby} text={"Gatsby"} logoTop="logo-top" />
              <div className="logo-second_item">
                <Logo logo={TailWind} text={"Tailwind"} />
              </div>
            </div>
            <div className="start-flex_item">
              <Logo
                logo={StyledComponent}
                text={"Styled Components"}
                imgClass="img"
                logoTop="logo-top"
              />
              <div className="logo-second_item">
                <Logo logo={Redux} text={"Redux"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stack;
