import React, { useRef } from "react";
import loveEmoji from "../../assets/images/love-emoji.png";
import ArrowRight from "../../assets/svg/arrow-right.svg";
import Button from "../../ui/button/Button";
import Media from "../../components/socials/Media";
import "./footer.css";
import AnimateText from "../animate/AnimateText";
import useIO from "../../hooks/useIO";

const Footer = () => {
  const footerRef = useRef(null);

  const { visibleElement } = useIO({ ref: footerRef });

  return (
    <section className="footer-section" ref={footerRef}>
      {visibleElement ? (
        <div className=" footer">
          <div className="footer-container">
            <div className="footer-container_flex">
              <div className="footer-container_flex_item">
                <div className="footer-container_flex_item_text ">
                  <div className="footer-idea">
                    <AnimateText
                      value="Have an Idea?
                 "
                    />
                  </div>
                  <div>
                    <AnimateText value="Make Magic With Me" delay={0.7} />
                  </div>
                </div>

                <div className="footer-container_flex_item_text"></div>
              </div>
              <div className="footer-container_flex_item">
                <img src={loveEmoji} alt="loveEmoji" className="love-emoji" />
              </div>
            </div>

            <div className="mid-footer-section">
              <div className="mid-footer-section_container">
                <div className="footer-text-pin">
                  <p className="mid-footer-section_container_text">
                    <span
                      className="SlidUp"
                      style={{ animationDelay: `${1}s` }}
                    >
                      If you're interested in discussing a project, feel free to
                      drop me a{" "}
                    </span>
                    <span
                      className="SlidUp"
                      style={{ animationDelay: `${1.2}s` }}
                    >
                      {" "}
                      message—I'd love to hear about it. Currently located in
                    </span>
                    <span
                      className=" SlidUp"
                      style={{ animationDelay: `${1.3}s` }}
                    >
                      Manchester, United Kingdom, I am available for
                      remote-friendly
                    </span>
                    <span
                      className=" SlidUp work"
                      style={{ animationDelay: `${1.4}s`, display: "block" }}
                    >
                      work.
                    </span>
                  </p>
                </div>
                <img
                  src={ArrowRight}
                  alt="arrowright"
                  className="arrow-right"
                />
                <div className="footer-btn">
                  <Button
                    text="Let’s Talk"
                    href="mailto:mercyokobi@gmail.com"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="media-section-wrapper">
            <div className="media-section-wrapper_flex">
              <Media props="footer-socials" />
              <p className="copyright">
                Designed by{" "}
                <span>
                  <a
                    href="https://jaykay.design/"
                    target="__blank"
                    className="jaykay"
                  >
                    Jaykay
                  </a>
                </span>{" "}
                - Developed by Me
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default Footer;
