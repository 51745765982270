import React, { useEffect, useRef, useState } from "react";
import Pin from "../../assets/svg/pin.svg";
import ConnectLine from "../../assets/svg/connect-line.svg";
import Button from "../../ui/button/Button";
import "./project.css";
import { useLocation } from "react-router-dom";
import AnimateText from "../animate/AnimateText";
import useIO from "../../hooks/useIO";
import { PROJECTS } from "../constants";

const Project = () => {
  const location = useLocation();
  const projectRef = useRef(null);
  const projectRefHeight = useRef(null);
  const [percentageScroll, setPercentageScroll] = useState();

  const { visibleElement } = useIO({ ref: projectRef });

  useEffect(() => {
    if (location.hash === "#project") {
      projectRef.current.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    function scrollHandler() {
      const clientRect = projectRef.current?.getBoundingClientRect();
      let scrolledPercentage =
        (clientRect?.top / (clientRect?.height - window.innerHeight)) * -100;
      if (scrolledPercentage >= 100) {
        scrolledPercentage = 99;
      }
      if (scrolledPercentage < 0) {
        scrolledPercentage = 0;
      }
      setPercentageScroll(scrolledPercentage);
    }

    function reinitalizeProjectAnimation() {
      window.removeEventListener("scroll", scrollHandler);

      if (window.outerWidth > 748) {
        window.addEventListener("scroll", scrollHandler);
      } else {
        setPercentageScroll(undefined);
      }
    }

    reinitalizeProjectAnimation();

    window.addEventListener("resize", reinitalizeProjectAnimation);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      console.log(e);
      if (e.ctrlKey && e.key === "h") {
        window.open("https://hackmamba.io/", "_blank");
      }
      if (e.ctrlKey && e.key === "b") {
        window.open("https://bitsika-test.netlify.app/", "_blank");
      }
      if (e.ctrlKey && e.key === "t") {
        e.preventDefault();
        window.open("https://www.talkroom.com/", "_blank");
      }
      if (e.ctrlKey && e.key === "o") {
        window.open("https://echoke-webapp.netlify.app/index.html", "_blank");
      }
      if (e.ctrlKey && e.key === "c") {
        window.open("https://console.bitsika.africa/", "_blank");
      }
    });
  }, []);

  const projectIndex = Math.floor(percentageScroll / (100 / 5));

  return (
    <div id="project" ref={projectRef}>
      {visibleElement ? (
        <div className="project-wrapper" ref={projectRefHeight}>
          <div className="mobile-project-head">
            <img className="pin-img" src={Pin} alt="pin" />
            <h2 className="header">Work i’m proud of</h2>
          </div>
          <div className="container project-container">
            <div className="project-flex-wrapper">
              <div className={projectIndex !== 0 ? "hide-on-desktop" : ""}>
                <ProjectItem id={0} {...{ percentageScroll }} />
              </div>
            </div>
            <div className="project-flex-wrapper">
              <div className={projectIndex !== 1 ? "hide-on-desktop" : ""}>
                <ProjectItem id={1} {...{ percentageScroll }} />
              </div>
            </div>

            <div className="project-flex-wrapper">
              <div className={projectIndex !== 2 ? "hide-on-desktop" : ""}>
                <ProjectItem id={2} {...{ percentageScroll }} />
              </div>
            </div>

            <div className="project-flex-wrapper">
              <div className={projectIndex !== 3 ? "hide-on-desktop" : ""}>
                <ProjectItem id={3} {...{ percentageScroll }} />
              </div>
            </div>

            <div className="project-flex-wrapper">
              <div className={projectIndex !== 4 ? "hide-on-desktop" : ""}>
                <ProjectItem id={4} {...{ percentageScroll }} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

function ProjectItem({ id, percentageScroll }) {
  const indicatorHeight = (percentageScroll / (100 / 5) - id) * 100;
  const result = PROJECTS.find((item) => item.id === id);

  return (
    <div className="project-container-items">
      <img className="pin-img" src={Pin} alt="pin" />
      <h2 className="header">Work i’m proud of</h2>
      <div className="project-flex">
        <div className="project-flex_items">
          <div className="project-number">
            <div className="project-num--animate">
              <h2 className="project-num">{id + 1}.</h2>
            </div>

            <div className="connect-lines--container">
              {/* prettier-ignore */}
              <svg className="connect-line" width="8" height="70" viewBox="0 0 8 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="66" r="3.5" transform="rotate(-90 4 66)" stroke="#656565" />
            <line x1="3.5" y1="55" x2="3.5" y2="15" stroke="#656565" strokeDasharray="4 8"/>
            <circle cx="4" cy="4" r="3.5" transform="rotate(-90 4 4)" stroke="#656565"/>
          </svg>
              <div
                className="indicator"
                style={{ height: `${indicatorHeight}%` }}
              >
                <img src={ConnectLine} alt="" className="connect-line" />
              </div>
            </div>
          </div>
          <div className="project-head-btn-container">
            <h2 className="project-head">
              <AnimateText value={result.title} />
            </h2>
            <div className="btn-container">
              <Button text="View Project" href={result.link} />
            </div>
          </div>
        </div>

        <div className="project-flex_items">
          <p className="project-subtext">
            <span
              className="ani-span SlidUp"
              style={{ animationDelay: `${0.6}s` }}
            >
              {result.desc1}
            </span>
            <span
              className="ani-span SlidUp"
              style={{ animationDelay: `${0.7}s` }}
            >
              {" "}
              {result.desc2}
            </span>
            <span
              className="ani-span SlidUp"
              style={{ animationDelay: `${0.8}s` }}
            >
              {result.desc3}
            </span>
          </p>
          <div className="btn-container-mobile">
            <Button text="View Project" href={result.link} />
          </div>
        </div>
      </div>
      <div className="image_items_container">
        <div className="image_items_container_items">
          <img
            className="project-img fadeLeft"
            src={require("../../assets/images/" + result?.keyboard + ".png")}
            alt="project-img"
          />
          <p className="control-key-wrapper">
          Press <span className="control-key"> {result?.text}</span> anywhere to view the project
          </p>
        </div>
        <div className="image_items_container_items">
          <div className="image_items_container_items_wrapper">
            <img
              className="project-img fadeLeft"
              src={require("../../assets/images/" + result?.gradient + ".png")}
              alt="project-img"
            />

            <img
              className="project-img sub-img fadeLeft"
              src={require("../../assets/images/" + result?.image + ".png")}
              alt="project-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project;
