import React from "react";
import btnVector from "../../assets/svg/btn-vector.svg";
import "./button.css";

const Button = ({ text, href }) => {
  return (
    <div className="btn-wrapper">
      <button>
        <a href={href} className="btn-link" target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      </button>

      <img className="btn-vector" src={btnVector} alt="" />
      <img className="btn-vector" src={btnVector} alt="" />
    </div>
  );
};

export default Button;
