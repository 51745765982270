import React, { useState, useEffect } from "react";
import logo from "../../assets/svg/logo.svg";
import { NavLink } from "react-router-dom";
import "./nav.css";
import { useLocation } from "react-router-dom";

const Nav = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [reduceNav, setReduceNav] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setNavOpen(false);
    if (location.hash === "#project") {
      setNavOpen(false);
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      const scrollY = window.scrollY;
      setReduceNav(scrollY > 10);
    });
  }, []);

  return (
    <div
      className="nav-container the-nav-bar"
      data-is-reduced={reduceNav && !navOpen}
    >
      <div className="nav-wrapper">
        <div className="nav-items">
          <div className="logo">
            <NavLink to="/">
              <img className="nav-logo-img" src={logo} alt="logo" />
            </NavLink>
          </div>

          <div className="menu-toggle" onClick={() => setNavOpen(!navOpen)}>
            <div className="hamBox">
              <span className={navOpen ? "lineTop spin" : "lineTop"}></span>
              <span
                className={navOpen ? "lineMiddle spin" : "lineMiddle"}
              ></span>
              <span
                className={navOpen ? "lineBottom spin" : "lineBottom"}
              ></span>
            </div>
          </div>

          <div className="nav-text-container">
            <p className="nav-text">
              I aim to create exceptional products and experiences with
              remarkable quality.
            </p>
          </div>
        </div>
        <ul className={`nav-links ${navOpen ? "activenavLinks" : " "} `}>
          <div className={`the-nav-bar-menu ${navOpen ? "activeMenu" : " "} `}>
            <div
              className={`the-nav-bar-menu-bg ${navOpen ? "activeBg" : " "} `}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <li className="ul-nav-item">
            <NavLink
              className={`link  ${navOpen ? "fadeUpLink" : " "}`}
              to="/#project"
              onClick={() => setNavOpen(!navOpen)}
              style={{
                top: 0,
                transitionDelay: navOpen ? "0.5s" : "0.2s",
              }}
            >
              Projects
            </NavLink>
          </li>
          <li className="ul-nav-item">
            <NavLink
              className={`link  ${navOpen ? "fadeUpLink" : " "}`}
              to="/about"
              onClick={() => setNavOpen(!navOpen)}
              style={{
                top: 0,
                transitionDelay: navOpen ? "0.6s" : "0.1s",
              }}
            >
              About
            </NavLink>
          </li>
          <li className="ul-nav-item">
            <NavLink
              className={`link  ${navOpen ? "fadeUpLink" : " "}`}
              to="https://drive.google.com/file/d/1uqoPJk2zvgYtk9b--new-fW0Y7vaQvIY/view"
              onClick={() => setNavOpen(!navOpen)}
              style={{
                top: 0,
                transitionDelay: navOpen ? "0.7s" : "0s",
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              MY CV
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="line"></div>
    </div>
  );
};

export default Nav;
