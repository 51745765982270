import React from "react";
import "./logo.css";

const Logo = ({ logo, text, imgClass, logoTop, ...props }) => {
  return (
    <div className={"logo-container " + logoTop}>
      <div className="sub-logo-container">
        <img className={"logo-img " + imgClass} src={logo} alt="" {...props} />
        <p className="logo-name">{text}</p>
      </div>
    </div>
  );
};

export default Logo;
